var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    {
      staticStyle: { "margin-top": "20px" },
      attrs: { shadow: "always", "body-style": { padding: "20px" } },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "label-position": "left",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Thu", prop: "moneyReceive" } },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: {
                      alias: "decimal",
                      groupSeparator: ".",
                      rightAlign: false,
                      autoGroup: true,
                      repeat: 20,
                      autoUnmask: true,
                      allowMinus: false,
                    },
                    expression:
                      "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                  },
                ],
                staticStyle: { width: "100%" },
                model: {
                  value: _vm.form.moneyReceive,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "moneyReceive", $$v)
                  },
                  expression: "form.moneyReceive",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Trả lại", prop: "moneyReturnCustomer" } },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: {
                      alias: "decimal",
                      groupSeparator: ".",
                      rightAlign: false,
                      autoGroup: true,
                      repeat: 20,
                      autoUnmask: true,
                      allowMinus: true,
                    },
                    expression:
                      "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: true}",
                  },
                ],
                staticStyle: { width: "100%" },
                attrs: { disabled: "" },
                model: {
                  value: _vm.form.moneyReturnCustomer,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "moneyReturnCustomer", $$v)
                  },
                  expression: "form.moneyReturnCustomer",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Shipper", prop: "shipperIds" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    size: "medium",
                    filterable: "",
                    multiple: "",
                  },
                  model: {
                    value: _vm.form.shipperIds,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "shipperIds", $$v)
                    },
                    expression: "form.shipperIds",
                  },
                },
                _vm._l(_vm.shippers, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: "" + item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }