"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/my-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = exports.asyncRoutes = void 0;
exports.resetRouter = resetRouter;
require("core-js/modules/es.object.to-string.js");
var _interopRequireWildcard2 = _interopRequireDefault(require("/Users/mac/Documents/project/my-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _index = _interopRequireDefault(require("@/layout/index.vue"));
_vue.default.use(_vueRouter.default);
/* Layout */

/* Router Modules */
/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */
/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
var constantRoutes = exports.constantRoutes = [{
  path: "/redirect",
  component: _index.default,
  hidden: true,
  children: [{
    path: "/redirect/:path*",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/redirect/index.vue"));
      });
    }
  }]
}, {
  path: "/login",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/auth/index.vue"));
    });
  },
  hidden: true
}, {
  path: "/auth-redirect",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/login/auth-redirect.vue"));
    });
  },
  hidden: true
}, {
  path: "/404",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/error-page/404.vue"));
    });
  },
  hidden: true
}, {
  path: "/401",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/error-page/401.vue"));
    });
  },
  hidden: true
}, {
  path: "/report",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/report/index.vue"));
    });
  },
  hidden: true
}, {
  path: "/",
  redirect: "/home",
  hidden: true
}, {
  path: "/home",
  component: _index.default,
  redirect: "/home/index",
  meta: {
    title: "Quản lý shipper",
    icon: "dashboard",
    permission: true
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/home/index.vue"));
      });
    },
    name: "home-index",
    meta: {
      title: "Điều đơn",
      permission: true
    }
  }, {
    path: "tour",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/tour/index.vue"));
      });
    },
    name: "tour-index",
    meta: {
      title: "Danh sách Tour",
      permission: true
    }
  }, {
    path: "for-control",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/for-control/index.vue"));
      });
    },
    name: "for-control-index",
    meta: {
      title: "Đối soát thủ công",
      permission: true
    }
  }, {
    path: "shipper-income",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/shipper-income/index.vue"));
      });
    },
    name: "shipper-income-index",
    meta: {
      title: "Quản lý thu nhập",
      permission: true
    }
  }, {
    path: "shipper",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/shipper/index.vue"));
      });
    },
    name: "shipper-index",
    meta: {
      title: "Danh sách shipper",
      permission: true
    }
  }]
}, {
  path: "/import",
  component: _index.default,
  redirect: "/import/index",
  meta: {
    title: "Quản lý nhập hàng",
    icon: "dashboard",
    permission: true
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/import/index.vue"));
      });
    },
    name: "import-index",
    meta: {
      title: "Lên lịch nhập hàng",
      permission: true
    }
  }, {
    path: "po",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/po/index.vue"));
      });
    },
    name: "po-index",
    meta: {
      title: "Quản lý PO",
      permission: true
    }
  }, {
    path: "config-limit",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/config-limit/index.vue"));
      });
    },
    name: "config-limit-index",
    meta: {
      title: "Cấu hình hạn mức",
      permission: true
    }
  }]
}, {
  path: "/rank",
  component: _index.default,
  redirect: "/rank/report",
  meta: {
    title: "Thi đua",
    icon: "dashboard",
    permission: true
  },
  children: [{
    path: "sale",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/sale/index.vue"));
      });
    },
    name: "sale-index",
    meta: {
      title: "Sale",
      permission: true
    }
  }, {
    path: "config-workdate",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/config-workdate/index.vue"));
      });
    },
    name: "config-workdate-index",
    meta: {
      title: "Cấu hình ngày nghỉ",
      permission: true
    }
  }, {
    path: "config-ratio",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/config-ratio/index.vue"));
      });
    },
    name: "config-ratio-index",
    meta: {
      title: "Cấu hình tỉ lệ huỷ đơn",
      permission: true
    }
  }, {
    path: "report",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/report/index.vue"));
      });
    },
    name: "report-index",
    meta: {
      title: "Báo cáo",
      permission: true
    }
  }]
}, {
  path: "/profile",
  component: _index.default,
  redirect: "/profile/index",
  meta: {
    title: "Cài đặt",
    icon: "qq",
    permission: false
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/profile/index.vue"));
      });
    },
    name: "Profile",
    meta: {
      title: "Hồ sơ cá nhân",
      permission: false
    }
  }]
}, {
  path: "*",
  redirect: "/404",
  hidden: true
}];
/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
var asyncRoutes = exports.asyncRoutes = [
  // 404 page must be placed at the end !!!
  // {
  //   path: '*',
  //   redirect: '/404',
  //   hidden: true
  // }
];
var createRouter = function createRouter() {
  return new _vueRouter.default({
    mode: "hash",
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0,
        x: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();
// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
function resetRouter() {
  var newRouter = createRouter();
}
var _default = exports.default = router;