"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/my-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.importProductApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var importProductApi = exports.importProductApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: '/import',
      params: params
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: '/import',
      method: 'POST',
      data: data
    });
  },
  update: function update(id, data) {
    return (0, _request.default)({
      url: "/import/".concat(id, "/update"),
      method: 'POST',
      data: data
    });
  },
  createImportWarehouse: function createImportWarehouse(id, data) {
    return (0, _request.default)({
      url: "/import/".concat(id, "/add/warehouse"),
      method: 'POST',
      data: data
    });
  },
  updateImportWarehouse: function updateImportWarehouse(id, data) {
    return (0, _request.default)({
      url: "/import/".concat(id, "/update/warehouse"),
      method: 'POST',
      data: data
    });
  },
  remove: function remove(id) {
    return (0, _request.default)({
      url: "/import/".concat(id, "/delete"),
      method: 'POST'
    });
  },
  addDetail: function addDetail(id, data) {
    return (0, _request.default)({
      url: "/import/".concat(id, "/add/detail"),
      method: 'post',
      data: data
    });
  },
  findAllDetail: function findAllDetail(params) {
    return (0, _request.default)({
      url: "/detailImport",
      params: params
    });
  },
  updateDetail: function updateDetail(id, data) {
    return (0, _request.default)({
      url: "/detailImport/".concat(id, "/update"),
      method: 'POST',
      data: data
    });
  },
  deleteDetail: function deleteDetail(id) {
    return (0, _request.default)({
      url: "/detailImport/".concat(id, "/delete"),
      method: 'post'
    });
  }
};