var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "table-po",
          attrs: { data: _vm.data, fit: "" },
        },
        [
          _c("el-table-column", {
            attrs: { label: "Số PO", prop: "code", width: "170" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_vm._v(" " + _vm._s(row.code) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Thông tin đặt" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "label-content" }, [
                      _c("label", { attrs: { for: "" } }, [_vm._v("Nhà CC: ")]),
                      _c("span", [
                        _vm._v(_vm._s(row.provider && row.provider.name)),
                      ]),
                    ]),
                    _c("div", { staticClass: "label-content" }, [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("Ngày đặt: "),
                      ]),
                      _c("span", [
                        _vm._v(_vm._s(_vm.formatDate(row.date_created))),
                      ]),
                    ]),
                    _c("div", { staticClass: "label-content" }, [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("Ngày giao: "),
                      ]),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$formatDate(row.dateDelivery))),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Thông tin Sản phẩm" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "label-content" }, [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("SL Sản phẩm: "),
                      ]),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$formatNumberVN(row.totalProduct))),
                      ]),
                    ]),
                    _c("div", { staticClass: "label-content" }, [
                      _c("label", { attrs: { for: "" } }, [_vm._v("SL đặt: ")]),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$formatNumberVN(row.totalQuantityImport))
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "label-content" }, [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("SL đã giao: "),
                      ]),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$formatNumberVN(row.totalQuantityDelivery))
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "label-content" }, [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("Tiến độ giao: "),
                      ]),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$formatNumberVN(row.delivertPercent)) + "%"
                        ),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "100", label: "Hành động" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "flex-direction": "column",
                        },
                      },
                      [
                        row.status != _vm.ImportProductStatus.Complete
                          ? _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  content: "Xóa",
                                  placement: "top",
                                  effect: "dark",
                                },
                              },
                              [
                                _c("el-button", {
                                  attrs: {
                                    type: "danger",
                                    size: "mini",
                                    icon: "el-icon-delete",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDelete(row)
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "el-tooltip",
                          {
                            staticStyle: { margin: "10px 0 0" },
                            attrs: {
                              content: "Sửa",
                              placement: "top",
                              effect: "dark",
                            },
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                type: "primary",
                                size: "mini",
                                icon: "el-icon-edit",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleEdit(row)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-tooltip",
                          {
                            staticStyle: { margin: "10px 0 0" },
                            attrs: {
                              content: "Danh sách phiếu nhập",
                              placement: "top",
                              effect: "dark",
                            },
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                type: "success",
                                size: "mini",
                                icon: "el-icon-document-copy",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleViewList(row)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "100", label: "Nhập kho" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.status != _vm.ImportProductStatus.Complete
                      ? _c(
                          "el-tooltip",
                          {
                            attrs: {
                              content: "Thêm phiếu",
                              placement: "top",
                              effect: "dark",
                            },
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                type: "success",
                                size: "mini",
                                icon: "el-icon-box",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleAddImportInvoice(row)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "170", label: "Trạng thái" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          row.status == "COMPLETE"
                            ? "Thành đơn"
                            : "Chưa thành đơn"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          page: _vm.query.page,
          limit: _vm.query.limit,
          total: _vm.total,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.query, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.query, "limit", $event)
          },
          pagination: function ($event) {
            return _vm.$emit("fetchData")
          },
        },
      }),
      _c("ImportPOModal", {
        ref: "importPOModal",
        on: {
          "submit:success": function ($event) {
            return _vm.$emit("fetchData")
          },
        },
      }),
      _c("import-modal", {
        ref: "importModal",
        on: {
          "submit:success": function ($event) {
            return _vm.$emit("fetchData")
          },
        },
      }),
      _c("list-import-warehouse-modal", {
        ref: "list",
        on: {
          "submit:success": function ($event) {
            return _vm.$emit("fetchData")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }