import { render, staticRenderFns } from "./ShipperModal.vue?vue&type=template&id=a2c0e226&scoped=true"
import script from "./ShipperModal.vue?vue&type=script&lang=ts"
export * from "./ShipperModal.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a2c0e226",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/mac/Documents/project/my-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a2c0e226')) {
      api.createRecord('a2c0e226', component.options)
    } else {
      api.reload('a2c0e226', component.options)
    }
    module.hot.accept("./ShipperModal.vue?vue&type=template&id=a2c0e226&scoped=true", function () {
      api.rerender('a2c0e226', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/shipper/components/ShipperModal.vue"
export default component.exports