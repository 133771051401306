var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.status == "create" ? "Thêm mới" : "Cập nhật",
        visible: _vm.visible,
        width: "1200px",
        top: "30px",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { "margin-top": "20px" },
          attrs: { data: _vm.transformTable, fit: "" },
        },
        [
          _c("el-table-column", {
            attrs: { label: "Sản phẩm", prop: "product.name" },
          }),
          _c("el-table-column", {
            attrs: { label: "SL đặt" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$formatNumberVN(row.quantityImport)) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "SL giao thực tế" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("el-input", {
                      attrs: { size: "normal" },
                      model: {
                        value: row.quantityDelivery,
                        callback: function ($$v) {
                          _vm.$set(row, "quantityDelivery", $$v)
                        },
                        expression: "row.quantityDelivery",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("template", { slot: "footer" }, [
        _c("div"),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                staticClass: "btn-save",
                on: {
                  click: function ($event) {
                    _vm.visible = false
                  },
                },
              },
              [_vm._v("Huỷ")]
            ),
            _c(
              "el-button",
              {
                staticClass: "btn-save",
                attrs: { type: "primary", loading: _vm.loadingSubmit },
                on: {
                  click: function ($event) {
                    _vm.status == "create" ? _vm.createData() : _vm.updateData()
                  },
                },
              },
              [_vm._v("Xác nhận")]
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }