"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/my-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.shipperIncomeApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var shipperIncomeApi = exports.shipperIncomeApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: '/shipperIncome',
      params: params
    });
  },
  detail: function detail(id, params) {
    return (0, _request.default)({
      url: "/shipperIncome/".concat(id),
      params: params
    });
  }
};