var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Cập nhật hạn mức",
        visible: _vm.visible,
        width: "600px",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            "label-position": "left",
            rules: _vm.rules,
            size: "normal",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Hạn mức", prop: "value" } },
            [
              _c("el-input", {
                attrs: { size: "normal" },
                model: {
                  value: _vm.form.value,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "value", $$v)
                  },
                  expression: "form.value",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "footer" }, [
        _c("div"),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                staticClass: "btn-save",
                on: {
                  click: function ($event) {
                    _vm.visible = false
                  },
                },
              },
              [_vm._v("Huỷ")]
            ),
            _c(
              "el-button",
              {
                staticClass: "btn-save",
                attrs: { type: "primary", loading: _vm.loadingSubmit },
                on: { click: _vm.updateData },
              },
              [_vm._v("Xác nhận")]
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }