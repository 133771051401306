var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container", attrs: { id: "income" } },
    [
      _c("div", { staticClass: "filter-container" }, [
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c("label", { attrs: { for: "" } }, [_vm._v("Ngày")]),
            _c("el-date-picker", {
              attrs: {
                date: "",
                "value-format": "yyyy-MM-dd",
                format: "dd/MM/yyyy",
                type: "daterange",
                "range-separator": "To",
                placeholder: "Chọn ngày",
                "start-placeholder": "Start date",
                "end-placeholder": "End date",
                clearable: false,
              },
              on: {
                change: function ($event) {
                  _vm.queryParams.page = 1
                  _vm.fetchData()
                },
              },
              model: {
                value: _vm.date,
                callback: function ($$v) {
                  _vm.date = $$v
                },
                expression: "date",
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.tableTransfrom,
            border: "",
            stripe: "",
            loading: _vm.loading,
            "summary-method": _vm.getSummaries,
            "show-summary": "",
          },
          on: { "row-click": _vm.handleView },
        },
        [
          _c("el-table-column", {
            attrs: { label: "STT", type: "index", width: "70", sortable: "" },
          }),
          _c("el-table-column", {
            attrs: { label: "Shipper", prop: "name", sortable: "" },
          }),
          _c("el-table-column", {
            attrs: { label: "Thu nhập", prop: "income", sortable: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(_vm._f("toThousandFilter")(row.income)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Số tour", prop: "totalTour", sortable: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("toThousandFilter")(row.totalTour)) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Số đơn", prop: "totalOrder", sortable: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("toThousandFilter")(row.totalOrder)) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.queryParams.page,
          limit: _vm.queryParams.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryParams, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryParams, "limit", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
      _c("detail-income", { ref: "detail" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }