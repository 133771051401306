var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Cập nhật tour",
        visible: _vm.visible,
        width: "1200px",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 16, offset: 0 } },
            [_c("list-order", { attrs: { form: _vm.form } })],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8, offset: 0 } },
            [
              _c("summary-tour", { attrs: { form: _vm.form } }),
              _c("tour-form", { ref: "tourForm", attrs: { form: _vm.form } }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              staticClass: "btn-save",
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _c(
            "el-button",
            {
              staticClass: "btn-save",
              attrs: { type: "primary", loading: _vm.loadingButton },
              on: { click: _vm.updateData },
            },
            [_vm._v("Xác nhận")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }