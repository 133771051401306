var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-card",
        { attrs: { shadow: "always", "body-style": { padding: "20px" } } },
        [
          _c("div", { staticClass: "summary-form" }, [
            _c("label", [_vm._v("Số đơn ")]),
            _c("span", { staticStyle: { "text-align": "right" } }, [
              _vm._v(":"),
            ]),
            _c(
              "span",
              {
                staticClass: "retail-price font-bold ",
                staticStyle: { "font-size": "40px" },
              },
              [_vm._v(_vm._s(_vm.form.dataTable.length))]
            ),
          ]),
          _c("div", { staticClass: "summary-form" }, [
            _c("label", [_vm._v("Tiền ship ")]),
            _c("span", { staticStyle: { "text-align": "right" } }, [
              _vm._v(":"),
            ]),
            _c(
              "span",
              {
                staticClass: "retail-price font-bold ",
                staticStyle: { "font-size": "40px" },
              },
              [_vm._v(_vm._s(_vm._f("toThousandFilter")(_vm.fee)))]
            ),
          ]),
          _c("div", { staticClass: "summary-form" }, [
            _c("label", [_vm._v("Trả shop ")]),
            _c("span", { staticStyle: { "text-align": "right" } }, [
              _vm._v(":"),
            ]),
            _c(
              "span",
              {
                staticClass: "retail-price font-bold ",
                staticStyle: { "font-size": "40px" },
              },
              [_vm._v(_vm._s(_vm._f("toThousandFilter")(_vm.shopFee)))]
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }