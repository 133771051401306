var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Mã đơn")]),
              _c("el-input", {
                ref: "codeInput",
                staticStyle: { width: "200px" },
                attrs: { placeholder: "", clearable: "" },
                on: { change: _vm.handleSearchOrder },
                model: {
                  value: _vm.code,
                  callback: function ($$v) {
                    _vm.code = $$v
                  },
                  expression: "code",
                },
              }),
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item btn-save",
              staticStyle: { transform: "translateY(0px)" },
              attrs: { type: "primary", loading: _vm.loadingButton },
              on: { click: _vm.handleDeleteAll },
            },
            [_vm._v(" Xóa tất cả ")]
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item btn-save",
              staticStyle: { transform: "translateY(0px)" },
              attrs: { type: "primary", loading: _vm.loadingButton },
              on: { click: _vm.handleControl },
            },
            [_vm._v(" Đối soát ")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.form.dataTable,
            border: "",
            stripe: "",
            loading: _vm.loading,
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "STT", type: "index", width: "70" },
          }),
          _c("el-table-column", {
            attrs: { label: "Id đơn hàng" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-link",
                      {
                        attrs: {
                          type: "primary",
                          href:
                            "https://v1.nhanh.vn/order/manage/detail?storeId=84054&id=" +
                            row.orderId,
                          target: "_blank",
                        },
                      },
                      [_vm._v(" " + _vm._s(row.orderId) + " ")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Khách hàng", prop: "customerName" },
          }),
          _c("el-table-column", {
            attrs: { label: "Trả shop" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("toThousandFilter")(
                            row.calcTotalMoney - row.shipFee
                          )
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Ship" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("toThousandFilter")(row.shipFee)) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Hành động", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          content: "Xóa",
                          placement: "top",
                          effect: "dark",
                        },
                      },
                      [
                        _c("el-button", {
                          attrs: {
                            type: "danger",
                            size: "mini",
                            icon: "el-icon-delete",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleDelete(row)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("for-control-modal", {
        ref: "forControlModal",
        on: { "submit:success": _vm.updateData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }