var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container", attrs: { id: "tour" } },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 16, offset: 0 } },
            [_c("list-order", { attrs: { form: _vm.form } })],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8, offset: 0 } },
            [
              _c("summary-tour", { attrs: { form: _vm.form } }),
              _c("tour-form", { ref: "tourForm", attrs: { form: _vm.form } }),
              _c(
                "el-button",
                {
                  staticClass: "btn-save",
                  staticStyle: { width: "100%", "margin-top": "20px" },
                  attrs: { type: "primary", loading: _vm.loadingButton },
                  on: { click: _vm.handleCreate },
                },
                [_vm._v("Xác nhận")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("print-tour", { ref: "printTour" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }