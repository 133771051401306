"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/my-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.providerApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var providerApi = exports.providerApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: '/provider',
      params: params
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: '/provider',
      method: 'POST',
      data: data
    });
  },
  update: function update(id, data) {
    return (0, _request.default)({
      url: "/provider/".concat(id, "/update"),
      method: 'POST',
      data: data
    });
  },
  remove: function remove(id) {
    return (0, _request.default)({
      url: "/provider/".concat(id, "/delete"),
      method: 'POST'
    });
  }
};