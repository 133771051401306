var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Nhập mã OTP",
        visible: _vm.visible,
        width: "1200px",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "flex-direction": "row",
            "justify-items": "center",
          },
        },
        [
          _c("v-otp-input", {
            ref: "otpInput",
            attrs: {
              "input-classes": "otp-input",
              separator: "-",
              "num-inputs": 6,
              "should-auto-focus": true,
              "is-input-num": true,
            },
            on: {
              "on-change": _vm.handleOnChange,
              "on-complete": _vm.handleOnComplete,
            },
          }),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              staticClass: "btn-save",
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _c(
            "el-button",
            {
              staticClass: "btn-save",
              attrs: { type: "primary", loading: _vm.loadingButton },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v("Xác nhận")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }